<template lang="">
    <div>
        <div class="main">
            <span>经度纬度:：</span>
            <input type="text" v-model="gps"/>
        </div>
        <div class="main">
            <span>详细地址:：</span>
            <input type="text" v-model="address"/>
        </div>
        <div class="btn" @click="goSubimt()">提交</div>
    </div>
</template>
<script>
export default {
    data(){
        return{
            gps:this.$route.query.latng,            
            address:this.$route.query.addr,
            id:this.$route.query.id
        }
    },
    created(){
        // alert(this.id)
        // //console.log(this.$route.query.latng)
    },
    methods:{
        goSubimt(){
            this.$http.post('/index.php/dispatch/gps',{id:this.id,gps:this.gps,address:this.address}).then(res=>{
                //console.log(res)
                if(res.data.code == 0){
                    this.$toast.success(res.data.msg)
                }else{
                    this.$toast.fail(res.data.msg)

                }
            })
        }
    }
}
</script>
<style lang="scss" scoped> 
    .main{
        font-size: 0.38rem;
        display: block;
        width: 85%;
        margin: 0.5rem auto;
        input{
            width: 6rem;
        }
    }
    .btn{
        height: 1rem;
        line-height: 1rem;
        font-size:0.5rem;
        text-align: center;
        border: 1px solid; 
        width: 4rem;
        margin:  2rem auto;
        border-radius: 10px;
           }
</style>